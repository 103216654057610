<template>
  <div>
    <div class="tw-border-b tw-border-gray-300">
      <div class="tw-flex tw-items-center tw-justify-between tw-px-6 tw-py-5">
        <h3 class="tw-text-2xl tw-capitalize tw-font-medium tw-inline-flex tw-items-center tw-font-heading">
          {{ $tc('_pricing', 2) }}
          <tooltip-select tip="_pricing" class="tw-ml-2"></tooltip-select>
        </h3>
      </div>
    </div>
    <div class="tw-py-4 tw-px-3">
      <PricingTable />
    </div>
  </div>
</template>

<script>
import PricingTable from '@/components/pricing/PricingTable.vue';
export default {
  props: [],
  components: {
    PricingTable,
  },
};
</script>
